<template>
  <div class="container flex flex-col flex-1">
    <div class="flex flex-col flex-1">
      <b-overlay
        :show="loading"
        :opacity="0.85"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
        blur="none"
        class="flex flex-col flex-1"
        overlay-tag="div"
      >
        <!-- <div class="position-relative text-center">
          <h1 style="color: #20409B;">LƯỢT KHÁM</h1>
          <h1
            style="color: #F86845;"
            class="position-absolute top-0 right-0"
          >{{ptInfo && mapPatientStatus(ptInfo.status)}}</h1>
        </div>-->

        <h1 class="mt-3 mt-md-5 mb-3 text-center text-pri-900">Lượt Khám</h1>
        <!-- <b-alert show variant="danger" class="text-center">
          <div>Lượt khám đã bị hủy</div>
          <div v-if="appTInfo.reject_reason">{{ appTInfo.reject_reason }}</div>
        </b-alert>-->

        <b-row cols="12" v-if="ptInfo">
          <b-col>
            <b-row class="align-start">
              <b-col cols="12" md="6" class="mb-4 mb-md-0">
                <b-card class="product-add-wrapper border h-full">
                  <b-alert show variant="info" class="text-center d-md-none">
                    <div class="text-2xl">{{ ptInfo && mapPatientStatus(ptInfo.status) }}</div>
                  </b-alert>
                  <b-row class="items-center mb-4">
                    <b-col cols="6" lg="7">
                      <div class="uppercase font-bold text-pri-900 text-center">
                        <div class="mb-2">SỐ THỨ TỰ</div>
                        <div class="text-6xl">{{ ptInfo.line_number }}</div>
                      </div>
                    </b-col>
                    <b-col cols="6" lg="5">
                      <div class="text-center" v-if="ptInfo.appt && ptInfo.appt.code">
                        <QRCode
                          v-if="ptInfo.appt && ptInfo.appt.code"
                          :value="`LH-${ptInfo.appt && ptInfo.appt.code}`"
                          :size="100"
                        ></QRCode>
                        <div
                          class="font-bold text-pri-900 whitespace-nowrap"
                        >{{ `LH-${ptInfo.appt.code}` }}</div>
                      </div>
                    </b-col>
                  </b-row>

                  <!-- User Info: Thông tin lịch khám -->
                  <b-row>
                    <!-- Field: name clinic -->
                    <b-col cols="12" md="12" class="mb-2">
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i class="el-icon-school" style="font-size: 40px; color: #20409B;" />
                        <p
                          class="fs-20 ml-2 mb-0"
                          style="color: #20409B;"
                        >{{(ptInfo.clinic && ptInfo.clinic.name) || defaultWs.workspace_info.name}}</p>
                      </div>
                    </b-col>

                    <b-col cols="12" md="12" class="mb-2">
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i class="el-icon-first-aid-kit" style="font-size: 40px; color: #20409B;" />
                        <p
                          class="fs-20 ml-2 mb-0"
                          style="color: #20409B;"
                        >{{ptInfo.consulting_room.name}}</p>
                      </div>
                    </b-col>

                    <b-col cols="12" md="12" class="mb-2">
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i
                          class="el-icon-location-information"
                          style="font-size: 40px; color: #20409B;"
                        />
                        <p
                          class="fs-20 ml-2 mb-0"
                          style="color: #20409B;"
                        >{{(ptInfo.clinic && ptInfo.clinic.address) || defaultWs.workspace_info.address}}</p>
                      </div>
                    </b-col>

                    <!-- Field: time -->
                    <b-col cols="12" md="12">
                      <div class="flex items-center ml-2 mr-2">
                        <img
                          style="max-width: 100%; height: 40px;"
                          src="../../../assets/images/medicalRegister/calendar_icon.png"
                          alt
                        />
                        <div class="ml-2 mb-0">
                          <p class="fs-20 mb-0" style="color: #424242;">Thời gian</p>
                          <p
                            class="fs-20 mb-0"
                            style="color: #20409B;"
                          >{{appUtils.formatGroupDateTime(ptInfo.start_time)}}</p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card class="product-add-wrapper border h-full">
                  <b-alert show variant="info" class="text-center d-none d-md-block">
                    <div class="text-2xl">{{ ptInfo && mapPatientStatus(ptInfo.status) }}</div>
                  </b-alert>
                  <b-row>
                    <!-- Field: Address -->
                    <b-col cols="12" md="12" class="mb-2">
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <img
                          style="max-width: 100%; height: 40px;"
                          src="../../../assets/images/medicalRegister/profile_icon.png"
                          alt
                        />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Bệnh nhân</p>
                        <p class="fs-20 ml-auto p-2 mb-0" style="color: #20409B;">
                          <span>{{ ptInfo.person && ptInfo.person.name }}</span>
                          <span class="mx-1">-</span>
                          <span
                            class="capitalize"
                          >{{ ptInfo.person && appUtils.getAges(ptInfo.person.birthday) }}</span>
                        </p>
                      </div>
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <img
                          style="max-width: 100%; height: 40px;"
                          src="../../../assets/images/medicalRegister/doctor_icon.png"
                          alt
                        />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Bác sĩ</p>
                        <p
                          class="fs-20 ml-auto p-2 mb-0"
                          style="color: #20409B;"
                        >{{ ptInfo.doctor.name }}</p>
                      </div>
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i class="el-icon-chat-square" style="font-size: 40px; color: #20409B;" />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Lý do khám</p>
                        <p
                          class="fs-20 ml-auto p-2 mb-0"
                          style="color:#20409B;"
                        >{{ ptInfo.visit_reason }}</p>
                      </div>
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i class="el-icon-tickets" style="font-size: 40px; color: #20409B;" />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Trạng thái hóa đơn</p>
                        <div class="ml-auto">
                          <div
                            v-if="formatterInvoiceStatus === 3"
                            class="text-box-success ml-auto p-2 mb-0"
                          >
                            <p
                              class="mb-0 fs-20"
                              style="color:#08B58B;"
                            >{{ mapStatusInvoiceName(formatterInvoiceStatus) }}</p>
                          </div>
                          <div
                            v-if="formatterInvoiceStatus === 3"
                            class="mt-1 text-xs text-center"
                          >{{ appUtils.formatTimeDate(ptInfo.appt.invoice.payment_date) }}</div>
                        </div>
                        <div
                          v-if="formatterInvoiceStatus === 1"
                          class="text-box-fail ml-auto p-2 mb-0"
                        >
                          <p
                            class="mb-0 fs-20"
                            style="color:#F2471D;"
                          >{{ mapStatusInvoiceName(formatterInvoiceStatus) }}</p>
                        </div>
                        <div
                          v-if="formatterInvoiceStatus === 2"
                          class="text-box-pending ml-auto p-2 mb-0"
                        >
                          <p
                            class="mb-0 fs-20"
                            style="color:#c0c40a;"
                          >{{ mapStatusInvoiceName(formatterInvoiceStatus) }}</p>
                        </div>
                      </div>
                      <div class="flex items-center ml-2 mr-2 mb-4">
                        <i class="el-icon-money" style="font-size: 40px; color: #20409B;" />
                        <p class="fs-20 ml-2 mb-0" style="color: #424242;">Đơn giá</p>
                        <p
                          class="fs-20 ml-auto p-2 mb-0"
                          style="color: red;"
                        >{{ ptInfo.appt.price ? appUtils.numberFormat(ptInfo.appt.price, 'VNĐ') : 'Hỗ trợ' }}</p>
                      </div>
                      <!-- <div class="flex items-center ml-2 mr-2 mb-4">
                      </div>-->
                      <UploadFilesTemp
                        :patientVisit="ptInfo"
                        :patientVisitStatus="ptInfo.status"
                        :consultationProp="ptInfo.consultation"
                        @refresh="getAppts"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BOverlay } from 'bootstrap-vue'
import { required } from '../../../../src/utils/validations/validations'
import 'vue-select/dist/vue-select.css'
import appUtils from '../../../utils/appUtils'
import { onBeforeMount, ref, onBeforeUnmount } from '@vue/composition-api'
import router from '../../../router'
import store from '../../../store'
import { mapGetters } from 'vuex'
import UploadFilesTemp from './components/UploadFilesTemp.vue'
import { PATIENT_VISIT, EXAMINATION_STATUS } from '../../../utils/constant'
import QRCode from 'qrcode.vue'

export default {
  name: 'MedicalRegisterPatientVisit',
  components: {
    UploadFilesTemp,
    BCard,
    BRow,
    BCol,
    BOverlay,
    QRCode
  },
  computed: {
    ...mapGetters('app', ['invoiceStatus']),
    ...mapGetters('registerMedical', ['defaultWs']),
    formatterInvoiceStatus () {
      return this.ptInfo?.appt?.invoice?.status
    }
  },
  data () {
    return {
      PATIENT_VISIT,
      EXAMINATION_STATUS,
      required,
      isShowQr: false,
      appUtils
    }
  },
  setup () {
    const loading = ref(false)
    const ptInfo = ref(null)
    const apptId = router.currentRoute.params.id
    const interval = ref(null)

    const fetchAppointMent = (isLoading = true) => {
      loading.value = !!isLoading
      store
        .dispatch('registerMedical/fetchPatientVisit', { id: apptId })
        .then(response => {
          ptInfo.value = response.data.data
          console.log(ptInfo.value)
          loading.value = false
        })
        .catch(e => {
          console.log(e)
          window.$toast.open({
            message: 'Lỗi lấy thông tin lịch khám',
            type: 'danger'
          })
          loading.value = false
        })
    }

    onBeforeMount(() => {
      fetchAppointMent()
      interval.value = setInterval(() => {
        fetchAppointMent(false)
      }, 1000 * 30)
    })

    onBeforeUnmount(() => {
      clearInterval(interval.value)
      interval.value = null
    })

    return {
      ptInfo,
      fetchAppointMent,
      loading
    }
  },
  methods: {
    getAppts () {
      this.fetchAppointMent()
    },
    mapStatusInvoiceName (value) {
      return this.invoiceStatus.find(x => x.value === value).name
    },
    mapPatientStatus (patientStatus) {
      return this.PATIENT_VISIT.find(x => x.value === patientStatus).label
    }
  }
}
</script>
<style scoped>
.form {
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.transition {
  width: 18px;
  transition: transform 0.1s ease-in-out;
}
.text-box-success {
  background: rgba(19, 219, 191, 0.15);
  border: 1px solid #08b58b;
  border-radius: 6px;
}
.text-box-pending {
  background: rgba(219, 199, 19, 0.15);
  border: 1px solid #c0c40a;
  border-radius: 6px;
}
.text-box-fail {
  background: rgba(216, 0, 0, 0.18);
  border: 1px solid #d80000;
  border-radius: 6px;
}
</style>
